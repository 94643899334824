/* eslint-disable no-underscore-dangle */
import httpInternal from '@/http/httpInternal';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  FINANCING_DISCLAIMER_TYPES,
  FINANCING_OPTIONS,
  LEASING_KEYS,
  LOAN_KEYS,
} from '@/constants/constants-financing';
import {
  createLeasingDataParams,
  createLeasingItemData, deductVAT,
} from '@/assets/js/helpers/financing-helpers';
import {
  CONFIGURATOR_STEPS,
  DYNAMIC_IMAGE_MODES,
  DYNAMIC_IMAGE_TYPES,
  OPTION_TYPES,
  SELECTION_TYPES,
} from '@/constants/constants-configurator';
import axios from 'axios';
import isFinite from 'lodash/isFinite';
import i18n from '@/setup/i18n-init';
import { sortCategoryOptions } from '@/assets/js/helpers/configurator-helpers';
import { FUEL_TYPES } from '@/constants/constants-vehicle';
import { roundNumber } from '@/assets/js/helpers/general-helpers';

const cacheMap = new Map();

const INITIAL_DELIVERY_DATA_INFO = () => ({
  estimatedDate: '',
  estimatedMonth: '',
  estimatedWeeks: '',
  estimatedYear: '',
  latestDate: '',
  latestMonth: '',
  latestWeeks: '',
  latestYear: '',
  selectionIdsAffectingDelivery: [],
});

const INITIAL_DELIVERY_DATA_REQUEST_ITEM = () => ({
  id: '',
  abortController: null,
});

const INITIAL_DYNAMIC_IMAGES_REQUEST_ITEM = () => ({
  id: '',
  abortController: null,
});

const INITIAL_MARKET_MODEL_DATA_ITEM = () => ({
  options: [],
  categories: [],
  groups: [],
  dynamicImages: {
    optimizable: false,
    request: INITIAL_DYNAMIC_IMAGES_REQUEST_ITEM(),
    loading: false,
    sources: {
      all: [],
      exterior: [],
      exterior360: [],
      interior: [],
      interior360: [],
    },
    activeMode: '',
    activeModeChanged: false,
    activeStudioIndex: 0,
  },
  deliveryData: {
    request: INITIAL_DELIVERY_DATA_REQUEST_ITEM(),
    error: false,
    loading: false,
    info: INITIAL_DELIVERY_DATA_INFO(),
  },
  optionsInventory: {},
});

const VehicleDataStore = {
  state: {
    models: [],
    marketModels: [],
    marketModelData: {},
  },
  getters: {
    getModels: (state) => state.models ?? [],

    getModelById: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => state.models?.find?.((model) => model.id === modelId),

    getModelByUrlKey: (state) => (urlKey) => (
      state.models?.find?.((model) => model.urlKey === urlKey)
    ),

    getModelImages: (state, getters) => ({
      modelId = getters.getActiveModelId,
    } = {}) => {
      const images = getters.getModelById(modelId)?.images ?? [];
      return images
        .map((image) => image?.url)
        .filter((image) => !!image);
    },

    getModelPreviewData: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => getters.getModelById(modelId)?.previewData ?? {},

    getModelActiveStartGroup: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => getters.getModelById(modelId)?.activeStartGroup ?? {},

    getModelPreReservationRequired: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => !!getters.getModelById(modelId)?.preReservationRequired,

    getModelUsesInventory: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => !!getters.getModelById(modelId)?.hasInventory,

    getModelDynamicStepsData: (state, getters) => ({
      modelId = getters.getActiveModelId,
      filterEmptySteps = false,
    } = {}) => {
      let dynamicSteps = getters.getModelById(modelId)?.configurator?.steps
        ?.filter?.((step) => step.isDynamic) ?? [];

      if (getters.isStockCarConfigurator()) {
        // @todo Fugly but efficient...
        dynamicSteps.unshift({
          isDynamic: true,
          isExternal: false,
          key: CONFIGURATOR_STEPS.stockCar,
          label: 'configurator.categoryIncluded',
          navLabel: 'configurator.categoryIncluded',
          subtitle: 'Stock Car subtitle',
          title: 'configurator.categoryIncluded',
        });
      }

      if (!filterEmptySteps) return dynamicSteps;

      dynamicSteps = dynamicSteps
        .filter((step) => {
          if (step.key === CONFIGURATOR_STEPS.stockCar && getters.isStockCarConfigurator()) return true;

          return getters.getMarketModelCategoriesByStep({
            stepKey: step.key,
          }).length;
        });

      return dynamicSteps;
    },

    getMarketModelsByModelId: (state, getters) => (
      modelId = getters.getActiveModelId,
    ) => sortBy(
      state.marketModels.filter?.((marketModel) => marketModel.modelId === modelId) ?? [],
      ['sortOrder'],
    ),

    getMarketModelById: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModels?.find((marketModel) => marketModel.id === marketModelId) ?? null,

    getMarketModelData: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId],

    getMarketModelDeliveryData: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId]?.deliveryData,

    getMarketModelDeliveryInfo: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => {
      let deliveryInfo = getters.getMarketModelDeliveryData(marketModelId)?.info ?? {};

      if (!deliveryInfo?.estimatedYear) {
        deliveryInfo = getters.getMarketModelById(marketModelId)?.deliveryInfo;
      }

      return deliveryInfo;
    },

    getMarketModelDeliveryHtml: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      showLatest = false,
      showLabel = false,
      errorTextOverride = '',
    } = {}) => {
      const { hasError } = getters.getMarketModelDeliveryData(marketModelId) ?? {};
      const info = getters.getMarketModelDeliveryInfo(marketModelId) ?? {};

      const marketModel = getters.getMarketModelById(marketModelId);
      if (!marketModel) return undefined;
      if (hasError) {
        return errorTextOverride || i18n.tc('error.fetchDeliveryData');
      }

      const {
        estimatedYear,
        estimatedMonth,
        estimatedWeeks,
        latestYear,
        latestMonth,
        latestWeeks,
      } = info;

      if (!isFinite(estimatedWeeks)) return undefined;

      const labelHtml = showLabel
        ? `<span class="label">${i18n.tc('general.estimatedDelivery')}: </span>`
        : '';

      const showLatestDate = showLatest
        && latestWeeks
        && estimatedWeeks !== latestWeeks;

      const estimatedMonthHtml = `<span class="estimated-month month">${estimatedMonth}</span>`;
      let estimatedYearHtml = `<span class="estimated-year year">${estimatedYear}</span>`;

      if (!showLatestDate) {
        return `${labelHtml}${estimatedMonthHtml} ${estimatedYearHtml}`;
      }

      estimatedYearHtml = estimatedYear === latestYear
        ? ''
        : estimatedYearHtml;

      const latestMonthHtml = `<span class="latest-month month">${latestMonth}</span>`;
      const latestYearHtml = `<span class="latest-year year">${latestYear}</span>`;

      return `${labelHtml}${estimatedMonthHtml} ${estimatedYearHtml} - ${latestMonthHtml} ${latestYearHtml}`;
    },

    shouldShowMarketModelDeliveryInfo: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => getters.getMarketModelDeliveryInfo(marketModelId)?.estimatedWeeks > 0,

    getMarketModelDynamicImagesData: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId]?.dynamicImages,

    getMarketModelStudioImages: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      type = DYNAMIC_IMAGE_TYPES.exterior,
      useDynamic = false,
    } = {}) => {
      const mm = getters.getMarketModelById(marketModelId);
      if (!mm) return [];

      const filterImages = (imageArray) => imageArray
        ?.map?.((image) => image.url)
        ?.filter?.((image) => !!image)
          ?? [];

      const defaultImageArray = mm?.images?.[type];

      if (!useDynamic) return filterImages(defaultImageArray);

      const dynamicImagesArray = getters.getMarketModelDynamicImagesData(marketModelId)
        ?.sources?.[type];

      if (!dynamicImagesArray?.length) return filterImages(defaultImageArray);

      return filterImages(dynamicImagesArray);
    },

    getMarketModelExterior360Images: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const imageArray = getters.getMarketModelDynamicImagesData(marketModelId)
        ?.sources?.exterior360 ?? [];
      return imageArray
        ?.map?.((imageObj) => imageObj.url)
        ?.filter?.((image) => !!image)
        ?? [];
    },

    getMarketModelInterior360Images: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const imageArray = getters.getMarketModelDynamicImagesData(marketModelId)
        ?.sources?.interior360 ?? [];
      return imageArray
        ?.map?.((imageObj) => imageObj.url)
        ?.filter?.((image) => !!image)?.[0]
        ?? '';
    },

    getMarketModelDynamicImagesActiveMode: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId]?.dynamicImages.activeMode,

    getMarketModelDynamicImagesActiveModeChanged: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId]?.dynamicImages.activeModeChanged,

    getMarketModelDynamicImagesActiveStudioIndex: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.marketModelData?.[marketModelId]?.dynamicImages.activeStudioIndex,

    getMarketModelDynamicImagesAvailableModes: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const imageModesConfig = getters.appConfig?.configurator?.imageSettings?.imageModes;
      const imageModes = !!imageModesConfig && !!imageModesConfig?.length
        ? imageModesConfig
        : Object.values(DYNAMIC_IMAGE_MODES);

      const availableModes = [];

      imageModes.forEach((mode) => {
        switch (mode) {
          case DYNAMIC_IMAGE_MODES.studio:
            if (
              getters.getMarketModelStudioImages({
                marketModelId, type: DYNAMIC_IMAGE_TYPES.all, useDynamic: true,
              }).length
            ) {
              availableModes.push(DYNAMIC_IMAGE_MODES.studio);
            }
            break;
          case DYNAMIC_IMAGE_MODES.exterior360:
            if (getters.getMarketModelExterior360Images({ marketModelId }).length) {
              availableModes.push(DYNAMIC_IMAGE_MODES.exterior360);
            }
            break;
          case DYNAMIC_IMAGE_MODES.interior360:
            if (getters.getMarketModelInterior360Images({ marketModelId }).length) {
              availableModes.push(DYNAMIC_IMAGE_MODES.interior360);
            }
            break;
          default:
            // Do nothing...
        }
      });

      return availableModes;
    },

    getMarketModelModelId: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => getters.getMarketModelById(marketModelId)?.modelId,

    getMarketModelOptions: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      ignoreFilter = false,
      associative = false,
    } = {}) => {
      if (!marketModelId) return [];

      let { options = {} } = getters.getMarketModelData(marketModelId) ?? {};

      options = !associative ? Object.values(options) : options;

      if (ignoreFilter) return options;

      const cacheKey = `marketModelOptionsFilter:${marketModelId}${getters.getConfiguratorCacheKeySuffix}`;

      if (cacheMap.has(cacheKey)) {
        return cacheMap.get(cacheKey);
      }

      const result = options.filter((option) => getters.isMarketModelOptionValid({
        marketModelId,
        marketModelOptionId: option.id,
        checkAdditionalOptions: false,
      }));

      cacheMap.set(cacheKey, result);

      return result;
    },

    getMarketModelStandardOptions: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includeAdditionalOptions = false,
    } = {}) => {
      const isStandard = (option) => {
        const standardCondition = option.type === SELECTION_TYPES.standard;
        const additionalOptionCondition = includeAdditionalOptions
          ? true
          : !getters.isOptionAdditionalOption({
            marketModelId,
            marketModelOptionId: option.id,
          });

        return standardCondition && additionalOptionCondition;
      };

      return getters.getMarketModelOptions({ marketModelId }).filter(isStandard);
    },

    getMarketModelBundledOptions: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => getters.getMarketModelOptions({ marketModelId }).filter((option) => option.type === 'BUNDLED'),

    getMarketModelOptionById: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const { options = {} } = getters.getMarketModelData(marketModelId) ?? {};
      return options[marketModelOptionId] ?? {};
    },

    getMarketModelOptionInventory: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => getters.getMarketModelData(marketModelId)?.optionsInventory?.[marketModelOptionId],

    getMarketModelCategories: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      associative = false,
    } = {}) => {
      const result = getters.getMarketModelData(marketModelId)?.categories ?? {};
      return !associative ? Object.values(result) : result;
    },

    getMarketModelGroups: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      associative = false,
    } = {}) => {
      const result = getters.getMarketModelData(marketModelId)?.groups ?? {};
      return !associative ? Object.values(result) : result;
    },

    getMarketModelCategoryById: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
    } = {}) => {
      const result = getters.getMarketModelCategories({ marketModelId, associative: true });
      const id = parseInt(categoryId, 10);
      return result[id] ?? {};
    },

    getMarketModelGroupById: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      groupId = '',
    } = {}) => {
      const result = getters.getMarketModelGroups({ marketModelId, associative: true });
      const id = parseInt(groupId, 10);
      return result[id] ?? {};
    },

    getMarketModelCategoriesByStep: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      stepKey = getters.getModelCurrentConfigStepKey(getters.getMarketModelModelId(marketModelId)),
    } = {}) => {
      const stepCategories = getters.getMarketModelCategories(marketModelId)
        ?.filter?.((category) => {
          const hasItems = getters.getMarketModelOptionsInCategory({
            marketModelId,
            categoryId: category.id,
          }).length > 0;
          const hasGroups = getters.getMarketModelGroupsByCategory({
            marketModelId,
            categoryId: category.id,
          }).length > 0;

          return category.step === stepKey && (hasItems || hasGroups);
        }) ?? [];

      return sortBy(stepCategories, ['sortOrder']);
    },

    getMarketModelGroupsByCategory: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
    } = {}) => {
      const cacheKey = `modelGroupsByCategory:${marketModelId}:${categoryId}${getters.getConfiguratorCacheKeySuffix}`;

      if (cacheMap.has(cacheKey)) {
        return cacheMap.get(cacheKey);
      }

      const categoryGroups = getters.getMarketModelGroups(marketModelId)
        ?.filter?.((group) => {
          const groupItems = getters.getMarketModelOptionsInGroup({
            marketModelId,
            groupId: group.id,
          });
          return group.categoryId === categoryId && groupItems.length > 0;
        }) ?? [];

      const result = sortBy(categoryGroups, ['sortOrder']);
      cacheMap.set(cacheKey, result);
      return result;
    },

    getMarketModelOptionsInCategory: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      categoryId = '',
    } = {}) => {
      const options = getters.getMarketModelOptions({ marketModelId });

      if (options.length === 0) return [];

      const category = getters.getMarketModelCategoryById({ marketModelId, categoryId });
      let categoryOptions = options
        .filter((option) => (option.categoryId === categoryId && option.groupId === null));

      if (getters.isStockCarConfigurator()) {
        categoryOptions = categoryOptions.filter((option) => !option.includedInStockCar);
      }

      if (category.hideOutOfStock) {
        categoryOptions = categoryOptions
          .filter((option) => getters.isOptionInStock({
            marketModelId,
            marketModelOptionId: option.id,
          }) === true);
      }

      return sortCategoryOptions(categoryOptions, category.itemsSortOrder);
    },

    getMarketModelOptionsInGroup: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      groupId = '',
    } = {}) => {
      const options = getters.getMarketModelOptions({ marketModelId });

      if (options.length === 0) return [];

      const group = getters.getMarketModelGroupById({ marketModelId, groupId });
      const category = getters.getMarketModelCategoryById({
        marketModelId,
        categoryId: group.categoryId,
      });

      let groupOptions = options
        .filter((option) => (option.groupId === groupId));

      if (getters.isStockCarConfigurator()) {
        groupOptions = groupOptions.filter((option) => !option.includedInStockCar);
      }

      if (category.hideOutOfStock) {
        groupOptions = groupOptions
          .filter((option) => getters.isOptionInStock({
            marketModelId,
            marketModelOptionId: option.id,
          }) === true);
      }

      return sortCategoryOptions(groupOptions, category.itemsSortOrder);
    },

    getMarketModelPrice: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      useDefaultFinancingSettings = false,
      skipBundledOptions = false,
      alwaysIncludeVat = false,
      ignoreDiscount = false,
      discountFinancingOptionType = financingOptionType,
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);

      let { listPrice: cashPrice = 0 } = marketModel?.financing ?? {};
      const { vatRatePercent = 0 } = marketModel?.financing ?? {};

      const { value: discountValue, hidden: discountHidden } = getters.getMarketModelDiscountData({
        marketModelId,
        financingOptionType: discountFinancingOptionType,
      });

      if (!ignoreDiscount || discountHidden) {
        cashPrice -= discountValue;
      }

      if (getters.isFinancingCompany(marketModel?.modelId) && !alwaysIncludeVat) {
        const mmRegistrationTax = marketModel?.financing?.registrationTax ?? 0;
        const cashPriceExTaxes = cashPrice - mmRegistrationTax;

        cashPrice = deductVAT(cashPriceExTaxes, vatRatePercent) + mmRegistrationTax;
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const mmLeasingData = getters.getMarketModelLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
          ignoreDiscount,
        });
        const mmLeasingValues = getters.getLeasingValues(mmLeasingData);

        return mmLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const mmLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice,
          financingConfig,
          includeFees: true,
          useDefaultFinancingSettings,
        });

        return mmLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (!skipBundledOptions) {
        const bundledSelectionsPrice = getters.getMarketModelSelectionsTotalCost({
          marketModelId,
          selectionIds: getters.getMarketModelBundledSelectionIds({ marketModelId }),
          financingOptionType,
          useDefaultFinancingSettings,
          ignoreDiscount,
          discountFinancingOptionType,
        });

        cashPrice += bundledSelectionsPrice;
      }

      return cashPrice;
    },

    getMarketModelDiscountData: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);
      const { discount: discountData = {} } = marketModel?.financing ?? {};

      return discountData?.[financingOptionType] ?? {
        value: 0,
        hidden: false,
      };
    },

    getMarketModelDiscountInformation: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);
      const { discountInformation = '' } = marketModel?.financing ?? {};

      return discountInformation;
    },

    getMarketModelAlwaysShowCampaign: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);
      const { alwaysShowCampaign = false } = marketModel?.financing ?? {};

      return alwaysShowCampaign;
    },

    getMarketModelOptionPrice: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      useDefaultFinancingSettings = false,
      ignoreAdditionalOptions = false,
      alwaysIncludeVat = false,
      ignoreDiscount = false,
      discountFinancingOptionType = financingOptionType,
    } = {}) => {
      const option = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      let cashPrice;
      let returnedPrice;

      if (!getters.isOptionPriceMileageYearBased({ marketModelId, marketModelOptionId })) {
        ({ listPrice: cashPrice = 0 } = option?.financing ?? {});
      } else {
        const mileageYearSettings = getters.getOptionMileageYearPriceSettings({
          marketModelId,
          marketModelOptionId,
        }) ?? {};
        const {
          annualMileages: annualMileageConfig = [],
        } = option?.financing?.mileageYearPrices ?? {};

        const activeMileageObj = annualMileageConfig
          .find((mileageObj) => mileageObj.mileage === mileageYearSettings.annualMileage);

        const { priceYears = [] } = activeMileageObj ?? {};
        const activePriceYearObj = priceYears
          .find((priceYearObj) => priceYearObj.period === mileageYearSettings.period) ?? {};

        cashPrice = activePriceYearObj.cashPrice ?? 0;
      }

      const { value: discountValue = 0, hidden: discountHidden } = getters.getMarketModelOptionDiscountData({
        marketModelId,
        marketModelOptionId,
        financingOptionType: discountFinancingOptionType,
      });

      if (!ignoreDiscount || discountHidden) {
        cashPrice -= discountValue;
      }

      const modelId = getters.getMarketModelModelId(marketModelId);
      const { vatRatePercent = 0 } = option?.financing ?? {};

      if (getters.isFinancingCompany(modelId) && !alwaysIncludeVat) {
        cashPrice = deductVAT(cashPrice, vatRatePercent);
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const optionLeasingData = getters.getMarketModelOptionLeasingDataParams({
          marketModelId,
          marketModelOptionId,
          useDefaultFinancingSettings,
          ignoreDiscount,
        });
        const optionLeasingValues = getters.getLeasingValues(optionLeasingData);

        returnedPrice = optionLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const financingConfig = getters.getMarketModelOptionFinancingConfig({
          marketModelId,
          marketModelOptionId,
        }) || {};

        const optionLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice,
          financingConfig,
          includeFees: false,
          useDefaultFinancingSettings,
        });

        returnedPrice = optionLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (!returnedPrice) returnedPrice = cashPrice;

      const selectedAdditionalOptions = getters.getSelectedAdditionalOptions({
        marketModelId,
        marketModelOptionId,
      });

      if (selectedAdditionalOptions && !ignoreAdditionalOptions) {
        selectedAdditionalOptions.forEach((additionalOption) => {
          returnedPrice += getters.getMarketModelOptionPrice({
            marketModelId,
            marketModelOptionId: additionalOption.id,
            financingOptionType,
            useDefaultFinancingSettings,
            alwaysIncludeVat,
            ignoreDiscount,
            discountFinancingOptionType,
          });
        });
      }

      return returnedPrice;
    },

    getMarketModelOptionDiscountData: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    } = {}) => {
      const option = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      const { discount: discountData = {} } = option?.financing ?? {};

      return discountData?.[financingOptionType] ?? {
        value: 0,
        hidden: false,
      };
    },

    getMarketModelOptionDiscountInformation: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const option = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      const { discountInformation = '' } = option?.financing ?? {};

      return discountInformation;
    },

    getMarketModelOptionAlwaysShowCampaign: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const option = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });
      const { alwaysShowCampaign = false } = option?.financing ?? {};

      return alwaysShowCampaign;
    },

    getMarketModelDeliveryCost: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      dealerNumber = getters.selectedDealer,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      useDefaultFinancingSettings = false,
      alwaysIncludeVat = false,
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);

      let cashPrice = marketModel?.financing?.fixedDeliveryCost;

      if (!cashPrice && cashPrice !== 0) {
        const dealer = getters.getDealer(dealerNumber)
          || getters.getDealer(getters.getConfigByKeyString('dealer.defaultDealer'));

        if (!(marketModel && dealer)) return 0;

        const { deliveryCosts = {} } = dealer;

        cashPrice = deliveryCosts?.[marketModel.mainType] ?? 0;
      }

      const { vatRatePercent = 0 } = marketModel?.financing ?? {};

      if (getters.isFinancingCompany(marketModel.modelId) && !alwaysIncludeVat) {
        cashPrice = deductVAT(cashPrice, vatRatePercent);
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const deliveryLeasingData = getters.getDeliveryLeasingDataParams({
          marketModelId,
          dealerNumber,
          useDefaultFinancingSettings,
        });
        const deliveryLeasingValues = getters.getLeasingValues(deliveryLeasingData);

        return deliveryLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const deliveryLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice,
          financingConfig,
          includeFees: false,
          useDefaultFinancingSettings,
        });

        return deliveryLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      return cashPrice;
    },

    getMarketModelSelectionsTotalCost: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      selectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      excludeExcludedFromCashTotal = false,
      useDefaultFinancingSettings = false,
      alwaysIncludeVat = false,
      ignoreDiscount = false,
      discountFinancingOptionType = financingOptionType,
    } = {}) => {
      if (!getters.isValidFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
        financingOptionType,
      })) return 0;

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const loanSelectionsCashTotal = getters.getConfigurationLoanCashTotal({
          marketModelId,
          includeMarketModel: false,
          includeDelivery: false,
          includeNewCarTaxation: false,
          includedSelectionIds: selectionIds,
          ignoreDiscount,
        });

        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const selectionsLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice: loanSelectionsCashTotal,
          financingConfig,
          includeFees: false,
          useDefaultFinancingSettings,
        });

        return selectionsLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      return selectionIds?.reduce?.((totalCost, selectionId) => {
        // @todo @refactor excludeExcludedFromCashTotal is confusing
        const itemBilledSeparately = getters.isOptionBilledSeparately({
          marketModelId,
          marketModelOptionId: selectionId,
        });
        const excludeFromTotal = excludeExcludedFromCashTotal && itemBilledSeparately;

        return excludeFromTotal
          ? totalCost
          : totalCost + getters
            .getMarketModelOptionPrice({
              marketModelId,
              marketModelOptionId: selectionId,
              financingOptionType,
              useDefaultFinancingSettings,
              ignoreAdditionalOptions: true,
              alwaysIncludeVat,
              ignoreDiscount,
              discountFinancingOptionType,
            });
      }, 0) ?? 0;
    },

    getMarketModelAndDeliveryPrice: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      dealerNumber = getters.selectedDealer,
      useDefaultFinancingSettings = false,
      alwaysIncludeVat = false,
      ignoreDiscount = false,
      discountFinancingOptionType = financingOptionType,
    } = {}) => {
      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const mmAndDeliveryLoanCashPrice = getters.getConfigurationLoanCashTotal({
          marketModelId,
          includeMarketModel: true,
          includeDelivery: true,
          includeNewCarTaxation: false,
          includedSelectionIds: null,
          ignoreDiscount,
        });

        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const mmAndDeliveryLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice: mmAndDeliveryLoanCashPrice,
          financingConfig,
          includeFees: true,
          useDefaultFinancingSettings,
        });

        return mmAndDeliveryLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const mmAndDeliveryLeasingDataParams = getters.getConfigurationLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
          includedSelectionIds: null,
          ignoreDiscount,
          addWeightBasedTax: false,
          addElectricProportionateVat: false,
        });

        const mmAndDeliveryLeasingValues = getters.getLeasingValues(mmAndDeliveryLeasingDataParams);

        return mmAndDeliveryLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      const marketModelPrice = getters.getMarketModelPrice({
        marketModelId,
        financingOptionType,
        useDefaultFinancingSettings,
        alwaysIncludeVat,
        ignoreDiscount,
        discountFinancingOptionType,
      });
      const deliveryCost = getters.getMarketModelDeliveryCost({
        marketModelId,
        dealerNumber,
        financingOptionType,
        useDefaultFinancingSettings,
        alwaysIncludeVat,
      });

      return marketModelPrice + deliveryCost;
    },

    getConfigurationTotalCostPerFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      excludeExcludedFromCashTotal = false,
      useDefaultFinancingSettings = false,
      alwaysIncludeVat = false,
      ignoreElectricVat = false,
      ignoreDiscount = false,
      discountFinancingOptionType = financingOptionType,
    } = {}) => {
      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const loanCashTotal = getters.getConfigurationLoanCashTotal({
          marketModelId,
          includeMarketModel: true,
          includeDelivery: true,
          includeNewCarTaxation: true,
          includedSelectionIds,
          ignoreDiscount,
        });

        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const totalLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice: loanCashTotal,
          financingConfig,
          includeFees: true,
          useDefaultFinancingSettings,
        });

        return totalLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const totalLeasingValues = getters.getConfigurationLeasingValues({
          marketModelId,
          useDefaultFinancingSettings,
          includedSelectionIds,
          ignoreDiscount,
          addElectricProportionateVat: true,
          addWeightBasedTax: true,
        });

        return totalLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      let totalCost = getters.getMarketModelAndDeliveryPrice({
        marketModelId,
        financingOptionType,
        useDefaultFinancingSettings,
        alwaysIncludeVat,
        ignoreDiscount,
        discountFinancingOptionType,
      });

      totalCost += getters.getMarketModelSelectionsTotalCost({
        marketModelId,
        financingOptionType,
        selectionIds: includedSelectionIds,
        excludeExcludedFromCashTotal,
        useDefaultFinancingSettings,
        alwaysIncludeVat,
        ignoreDiscount,
        discountFinancingOptionType,
      });

      // @todo Temp:ish or maybe not?
      totalCost += getters.getConfigurationWeightBasedTax({ marketModelId });

      if (!ignoreElectricVat) {
        totalCost += getters.getConfigurationElectricVatTotal({
          marketModelId,
          includedSelectionIds,
          ignoreDiscount,
          discountFinancingOptionType,
        });
      }

      return totalCost;
    },

    getConfigurationCashTotalPerFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      ignoreDiscount = false,
    } = {}) => {
      switch (financingOptionType) {
        case FINANCING_OPTIONS.leasing:
          return getters.getConfigurationLeasingValues({
            marketModelId,
            ignoreDiscount,
          })?.[LEASING_KEYS.cashPrice];
        case FINANCING_OPTIONS.loan:
          return getters.getConfigurationLoanCashTotal({
            marketModelId,
            includeMarketModel: true,
            includeDelivery: true,
            includeNewCarTaxation: true,
            ignoreDiscount,
          });
        default:
          return getters.getConfigurationTotalCostPerFinancingOption({
            marketModelId,
            financingOptionType: FINANCING_OPTIONS.cash,
            ignoreDiscount,
          });
      }
    },

    getConfigurationLoanCashTotal: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includeMarketModel = true,
      includeDelivery = true,
      includeNewCarTaxation = true,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
    } = {}) => {
      let totalLoanCashPrice = 0;

      if (includeMarketModel) {
        totalLoanCashPrice += getters.getMarketModelPrice({
          marketModelId,
          financingOptionType: FINANCING_OPTIONS.cash,
          ignoreDiscount,
          discountFinancingOptionType: FINANCING_OPTIONS.loan,
        });
      }

      if (includeDelivery && includeMarketModel) {
        totalLoanCashPrice += getters.getMarketModelDeliveryCost({
          marketModelId,
          financingOptionType: FINANCING_OPTIONS.cash,
        });
      }

      if (includeNewCarTaxation && includeMarketModel) {
        totalLoanCashPrice += getters.getConfigurationWeightBasedTax({ marketModelId });
        totalLoanCashPrice += getters.getConfigurationElectricVatTotal({
          marketModelId,
          includedSelectionIds,
          discountFinancingOptionType: FINANCING_OPTIONS.loan,
        });
      }

      totalLoanCashPrice += getters.getMarketModelSelectionsTotalCost({
        marketModelId,
        selectionIds: includedSelectionIds,
        excludeExcludedFromCashTotal: true,
        financingOptionType: FINANCING_OPTIONS.cash,
        ignoreDiscount,
        discountFinancingOptionType: FINANCING_OPTIONS.loan,
      });

      return totalLoanCashPrice;
    },

    getSharedLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
    }) => {
      const marketModel = getters.getMarketModelById(marketModelId);
      if (!marketModel) return createLeasingDataParams();

      const mmFinancingConfig = getters.getMarketModelFinancingConfig(marketModelId);
      const mmLeasingSettings = getters.getLeasingSettings({
        financingStateKey: marketModelId,
        financingConfig: mmFinancingConfig,
        useDefaultFinancingSettings,
      });

      return createLeasingDataParams({
        advanceRentPercent: mmLeasingSettings[LEASING_KEYS.advanceRentPercent],
        nominalInterestRate: mmLeasingSettings[LEASING_KEYS.nominalInterestRate],
        leasePeriodYears: mmLeasingSettings[LEASING_KEYS.leasePeriod],
        annualMileage: mmLeasingSettings[LEASING_KEYS.annualMileage],
        establishmentFee: mmLeasingSettings[LEASING_KEYS.establishmentFee],
        perTermFee: mmLeasingSettings[LEASING_KEYS.perTermFee],
        useVatCorrection: getters.shouldUseLeasingVatCorrection({ marketModelId }),
      });
    },

    getMarketModelLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
      ignoreDiscount = false,
    }) => {
      const mmLeasingDataParams = getters.getSharedLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      const marketModel = getters.getMarketModelById(marketModelId);
      if (!marketModel) return mmLeasingDataParams;

      const mmFinancingConfig = getters.getMarketModelFinancingConfig(marketModelId);
      const mmLeasingSettings = getters.getLeasingSettings({
        financingStateKey: marketModelId,
        financingConfig: mmFinancingConfig,
        useDefaultFinancingSettings,
      });

      const { [LEASING_KEYS.residualValuePercent]: mmResidualValuePercent } = mmLeasingSettings;

      const mmCashPrice = getters.getMarketModelPrice({
        marketModelId,
        financingOptionType: FINANCING_OPTIONS.cash,
        skipBundledOptions: true,
        ignoreDiscount,
        discountFinancingOptionType: FINANCING_OPTIONS.leasing,
      });
      const { vatRatePercent: mmVatRatePercent = 0 } = marketModel?.financing ?? {};
      const { registrationTax: mmTaxes = 0 } = marketModel?.financing ?? {};

      // @todo ADD weightBasedTax TO TAXES? <-- NO! Not according to the excel sheet...

      let discountedValue = 0;
      let calculateResidualValueWithDiscountedPrice = false;

      if (!ignoreDiscount) {
        ({
          value: discountedValue = 0,
          calculateResidualValueWithDiscountedPrice = false,
        } = getters.getMarketModelDiscountData({
          marketModelId,
          financingOptionType: FINANCING_OPTIONS.leasing,
        }));
      }

      mmLeasingDataParams.items.push(createLeasingItemData({
        cashPrice: mmCashPrice,
        residualValuePercent: mmResidualValuePercent,
        vatRatePercent: mmVatRatePercent,
        taxes: mmTaxes,
        discountedValue,
        calculateResidualValueWithDiscountedPrice,
        includeFees: true,
      }));

      getters.getMarketModelBundledSelectionIds({ marketModelId }).forEach((selectionId) => {
        const { items: selectionItemArray } = getters.getMarketModelOptionLeasingDataParams({
          marketModelId,
          marketModelOptionId: selectionId,
          useDefaultFinancingSettings,
          ignoreDiscount,
        });
        mmLeasingDataParams.items.push(...selectionItemArray);
      });

      return mmLeasingDataParams;
    },

    getMarketModelOptionLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      useDefaultFinancingSettings = false,
      ignoreDiscount = false,
    }) => {
      const optionLeasingDataParams = getters.getSharedLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });
      if (!marketModelOption) return optionLeasingDataParams;

      const optionFinancingConfig = getters.getMarketModelOptionFinancingConfig({
        marketModelId,
        marketModelOptionId,
      });
      const optionLeasingSettings = getters.getLeasingSettings({
        financingStateKey: marketModelId,
        financingConfig: optionFinancingConfig,
        useDefaultFinancingSettings,
      });

      const {
        [LEASING_KEYS.residualValuePercent]: optionResidualValuePercent,
      } = optionLeasingSettings;

      const optionCashPrice = getters.getMarketModelOptionPrice({
        marketModelId,
        marketModelOptionId,
        financingOptionType: FINANCING_OPTIONS.cash,
        ignoreAdditionalOptions: true,
        ignoreDiscount,
        discountFinancingOptionType: FINANCING_OPTIONS.leasing,
      });

      const optionVatRatePercent = marketModelOption?.financing?.vatRatePercent;
      const isAnnualService = marketModelOption?.accessoryType === 'annual-service';

      let discountedValue = 0;
      let calculateResidualValueWithDiscountedPrice = false;

      if (!ignoreDiscount) {
        ({
          value: discountedValue = 0,
          calculateResidualValueWithDiscountedPrice = false,
        } = getters.getMarketModelOptionDiscountData({
          marketModelId,
          marketModelOptionId,
          financingOptionType: FINANCING_OPTIONS.leasing,
        }));
      }

      optionLeasingDataParams.items.push(createLeasingItemData({
        cashPrice: optionCashPrice,
        residualValuePercent: optionResidualValuePercent,
        vatRatePercent: optionVatRatePercent,
        taxes: 0,
        discountedValue,
        calculateResidualValueWithDiscountedPrice,
        includeFees: false,
        isAnnualService,
      }));

      return optionLeasingDataParams;
    },

    getDeliveryLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      dealerNumber = getters.selectedDealer,
      useDefaultFinancingSettings = false,
    }) => {
      const deliveryLeasingDataParams = getters.getSharedLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      const marketModel = getters.getMarketModelById(marketModelId);
      if (!marketModel) return deliveryLeasingDataParams;

      const deliveryCashPrice = getters.getMarketModelDeliveryCost({
        marketModelId,
        dealerNumber,
        financingOptionType: FINANCING_OPTIONS.cash,
      });

      const { vatRatePercent: deliveryVatRatePercent = 0 } = marketModel?.financing ?? {};

      deliveryLeasingDataParams.items.push(createLeasingItemData({
        cashPrice: deliveryCashPrice,
        residualValuePercent: 0,
        vatRatePercent: deliveryVatRatePercent,
        taxes: 0,
        includeFees: false,
      }));

      return deliveryLeasingDataParams;
    },

    getConfigurationLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
      addWeightBasedTax = true,
      addElectricProportionateVat = true,
    } = {}) => {
      const configurationLeasingDataParams = getters.getMarketModelLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
        ignoreDiscount,
      });

      if (addElectricProportionateVat) {
        configurationLeasingDataParams.electricProportionateVatRate = getters.getConfigurationElectricVatProportionateVatRate({
          marketModelId,
          includedSelectionIds,
          discountFinancingOptionType: FINANCING_OPTIONS.leasing,
        });

        const { items: electricVatItemsArrays } = getters.getConfigurationElectricVatLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
          includedSelectionIds,
        });
        configurationLeasingDataParams.items.push(...electricVatItemsArrays);
        // @todo Add electricVat item
      }

      const { items: deliveryItemsArray } = getters.getDeliveryLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      configurationLeasingDataParams.items.push(...deliveryItemsArray);

      if (addWeightBasedTax) {
        const { items: weightBasedTaxItemsArray } = getters.getConfigurationWeightBasedTaxLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
        });

        configurationLeasingDataParams.items.push(...weightBasedTaxItemsArray);
      }

      if (Array.isArray(includedSelectionIds)) {
        const selectionsItemsArray = [];
        includedSelectionIds.forEach((selectionId) => {
          const { items: selectionItemArray } = getters.getMarketModelOptionLeasingDataParams({
            marketModelId,
            marketModelOptionId: selectionId,
            useDefaultFinancingSettings,
            ignoreDiscount,
          });

          selectionsItemsArray.push(...selectionItemArray);
        });
        configurationLeasingDataParams.items.push(...selectionsItemsArray);
      }

      return configurationLeasingDataParams;
    },

    getConfigurationLeasingValues: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
      addWeightBasedTax = true,
      addElectricProportionateVat = true,
      roundNumbers = true,
    } = {}) => {
      const configurationLeasingDataParams = getters.getConfigurationLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
        includedSelectionIds,
        ignoreDiscount,
        addWeightBasedTax,
        addElectricProportionateVat,
      });

      return getters.getLeasingValues(configurationLeasingDataParams, roundNumbers);
    },

    getMarketModelPriceDisclaimer: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      addAsterisk = true,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      useDefaultFinancingSettings = false,
      type = FINANCING_DISCLAIMER_TYPES.full,
      ignoreDiscount = false,
      checkOnly = false,
    } = {}) => {
      if (!getters.isValidFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
        financingOptionType,
      })) return null;

      const financingConfig = getters.getMarketModelFinancingConfig(marketModelId);

      const disclaimerType = Object.values(FINANCING_DISCLAIMER_TYPES).includes(type)
        ? type
        : FINANCING_DISCLAIMER_TYPES.full;

      let disclaimer = financingConfig?.[financingOptionType]?.disclaimer?.[disclaimerType] ?? null;

      if (!disclaimer) return null;

      if (checkOnly) return true;

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const configurationLeasingDataParams = getters.getConfigurationLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
          includedSelectionIds,
          ignoreDiscount,
        });

        disclaimer = getters.getLeasingDisclaimer({
          leasingDataParams: configurationLeasingDataParams,
          rawDisclaimer: disclaimer,
        });
      }

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const loanCashTotal = getters.getConfigurationLoanCashTotal({
          marketModelId,
          includeMarketModel: true,
          includeDelivery: true,
          includeNewCarTaxation: true,
          includedSelectionIds,
          ignoreDiscount,
        });

        disclaimer = getters.getLoanDisclaimer({
          financingStateKey: marketModelId,
          rawDisclaimer: disclaimer,
          cashPrice: loanCashTotal,
          financingConfig,
          includeFees: true,
          useDefaultFinancingSettings,
        });
      }

      const asterisk = addAsterisk
        ? getters.getFinancingAsterisk({
          financingStateKey: getters.getMarketModelModelId(marketModelId),
          financingOptionType,
          marketModelId,
        })
        : '';

      return disclaimer
        ? `${asterisk}${disclaimer}`
        : null;
    },

    getMarketModelFinancingConfig: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => {
      const marketModelFinancingConfig = getters.getMarketModelById(
        marketModelId,
      )?.financing?.config ?? {};

      const { leasing = {}, loan = {} } = marketModelFinancingConfig;
      return { leasing, loan };
    },

    getMarketModelOptionFinancingConfig: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return marketModelOption
        ? marketModelOption?.financing?.config ?? {}
        : getters.getMarketModelFinancingConfig(marketModelId);
    },

    isOptionBundledInMarketModel: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return marketModelOption?.type === 'BUNDLED';
    },

    isOptionPriceHidden: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return marketModelOption
        ? !!marketModelOption?.hidePrice || marketModelOption?.accessoryType === 'dealer-option'
        : false;
    },

    isOptionAvailableForDealer: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      dealerNumber = getters.selectedDealer,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      const { dealerSpecific } = marketModelOption;
      if (!Array.isArray(dealerSpecific) || !dealerSpecific?.length) return true;

      return !!dealerSpecific.includes(dealerNumber);
    },

    isOptionAvailableForCustomerType: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      const modelId = getters.getMarketModelModelId(marketModelId);
      const customerType = getters.getFinancingCustomerType(modelId);
      const { excludedCustomerTypes } = marketModelOption;
      if (!Array.isArray(excludedCustomerTypes) || !excludedCustomerTypes.length) return true;

      return !excludedCustomerTypes.includes(customerType);
    },

    isOptionAvailableForFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const { excludedFinancingOptions = [] } = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      }) ?? {};

      if (!Array.isArray(excludedFinancingOptions) || !excludedFinancingOptions.length) return true;

      const { paymentType } = getters.getFinancingOptionData() ?? {};

      return !excludedFinancingOptions.includes(paymentType);
    },

    isOptionRequiredForFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getActiveModelId,
        allowCashClones: true,
      }),
    } = {}) => {
      const { requiredForFinancingOptions = [] } = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      }) ?? {};

      if (!Array.isArray(requiredForFinancingOptions) || !requiredForFinancingOptions.length) return false;

      const { paymentType } = getters.getFinancingOptionData({ financingOptionType }) ?? {};

      return requiredForFinancingOptions.includes(paymentType);
    },

    isOptionBilledSeparately: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return marketModelOption
        ? !!marketModelOption.excludeFromLoan && financingOptionType === FINANCING_OPTIONS.loan
        : false;
    },

    isOptionInStock: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!getters.getModelUsesInventory(modelId)) return true;

      if (getters.isChangingOrder(modelId)) {
        const {
          selections: orderSelections = [],
          marketModelId: orderMarketModelId = '',
        } = getters.getOpenedOrderState(modelId);
        const isOrderSelection = !!orderSelections
          .find((selection) => selection.id === marketModelOptionId);
        const isOrderMarketModel = orderMarketModelId
          === getters.getModelSelectedMarketModelId(modelId);

        return isOrderMarketModel && isOrderSelection
          ? true
          : !!getters.getMarketModelOptionInventory({ marketModelId, marketModelOptionId });
      }

      return !!getters.getMarketModelOptionInventory({ marketModelId, marketModelOptionId });
    },

    isOptionPriceMileageYearBased: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return !!marketModelOption?.financing?.mileageYearPrices;
    },

    isOptionAdditionalOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      return !!marketModelOption?.additionalOptionsData?.parents?.length > 0;
    },

    selectionsOutOfStockWithOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!getters.getModelUsesInventory(modelId)) return [];
      const marketModelOption = getters.getMarketModelOptionById({
        marketModelId,
        marketModelOptionId,
      });

      if (marketModelOption.objectType === OPTION_TYPES.accessory) return [];

      const inventory = getters.getMarketModelOptionInventory({
        marketModelId, marketModelOptionId,
      });
      const selectionIds = getters.getMarketModelSelectionIds({ marketModelId })
        ?.filter?.((selectionId) => {
          const selection = getters.getMarketModelOptionById({
            marketModelId, marketModelOptionId: selectionId,
          });
          return selection?.categoryId !== marketModelOption.categoryId;
        }) ?? [];

      return inventory
        ? selectionIds.filter((selection) => !inventory.includes(selection))
        : [];
    },

    isMarketModelInStock: (state, getters) => (marketModelId) => {
      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!getters.getModelUsesInventory(modelId)) return true;

      const marketModel = getters.getMarketModelById(marketModelId);
      if (!marketModel) return false;

      if (getters.isChangingOrder()) {
        return getters.getOpenedOrderState(modelId).marketModelId === marketModelId
          ? true
          : !!marketModel.inStock;
      }

      return !!marketModel.inStock;
    },

    isMarketModelElectric: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);

      if (!marketModel) return false;

      const { fuelType } = marketModel;
      return fuelType === FUEL_TYPES.electric;
    },

    isMarketModelDiscounted: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    } = {}) => {
      const alwaysShow = getters.getMarketModelAlwaysShowCampaign({ marketModelId });

      if (alwaysShow) return true;

      const {
        value = 0,
        hidden = false,
      } = getters.getMarketModelDiscountData({ marketModelId, financingOptionType });

      return value > 0 && !hidden;
    },

    isMarketModelOptionDiscounted: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
    } = {}) => {
      const alwaysShow = getters.getMarketModelOptionAlwaysShowCampaign({ marketModelId, marketModelOptionId });

      if (alwaysShow) return true;

      const {
        value = 0,
        hidden = false,
      } = getters.getMarketModelOptionDiscountData({ marketModelId, marketModelOptionId, financingOptionType });

      return value > 0 && !hidden;
    },

    isConfigurationDiscounted: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      selectionIds = [],
    } = {}) => {
      const marketModelDiscounted = getters.isMarketModelDiscounted({ marketModelId, financingOptionType });

      const selectionIdsToCheck = selectionIds?.length
        ? selectionIds
        : getters.getMarketModelSelectionIds({ marketModelId });

      const anySelectionDiscounted = selectionIdsToCheck
        .some((marketModelOptionId) => getters.isMarketModelOptionDiscounted({
          marketModelId, marketModelOptionId, financingOptionType,
        }));

      return marketModelDiscounted || anySelectionDiscounted;
    },

    getMarketModelUpgrades: (state, getters) => ({
      marketModelId = getters.getModelBaseMarketModelId(),
    } = {}) => {
      const marketModel = getters.getMarketModelById(marketModelId);

      const modelId = marketModel?.modelId;
      const { upgradesToMarketModel = [] } = marketModel?.configurator ?? {};

      if (!modelId) return [];

      const marketModels = getters.getMarketModelsByModelId(modelId);

      const filterFunction = (mm) => mm.id !== marketModelId
        && upgradesToMarketModel?.includes?.(mm.id);

      return marketModels.filter((mm) => filterFunction(mm));
    },

    isMarketModelAnUpgrade: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const modelId = getters.getMarketModelModelId(marketModelId);

      const validMarketModels = getters.getMarketModelsByModelId(modelId);

      return validMarketModels.some((mm) => mm?.configurator?.upgradesToMarketModel?.includes?.(marketModelId));
    },

    isMarketModelValidUpgradeForBase: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      baseMarketModelId = getters.getModelBaseMarketModelId(),
    } = {}) => {
      const validUpgrades = getters.getMarketModelUpgrades({ marketModelId: baseMarketModelId });
      return !!validUpgrades.find((marketModel) => marketModelId === marketModel.id);
    },

    isUpgradeToMarketModelSelected: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      if (getters.isMarketModelAnUpgrade({ marketModelId })) return false;
      if (marketModelId === getters.getModelSelectedMarketModelId()) return false;

      const validUpgradeIds = getters.getMarketModelUpgrades({ marketModelId }).map((marketModel) => marketModel.id);
      return validUpgradeIds.includes(getters.getModelSelectedMarketModelId());
    },

  },
  mutations: {
    UPDATE_MARKET_MODEL_DATA_STATE(state, {
      marketModelId = '',
      marketModelData = INITIAL_MARKET_MODEL_DATA_ITEM(),
    } = {}) {
      if (!marketModelId) return;
      this._vm.$set(state.marketModelData, marketModelId, marketModelData);
    },

    UPDATE_MODELS(state, models = []) {
      state.models = models;
    },

    ADD_MODEL(state, model = {}) {
      if (!model?.id) return;

      const existingIndex = state.models
        .findIndex((existingModel) => existingModel.id === model.id);

      existingIndex === -1
        ? state.models.push(model)
        : state.models.splice(existingIndex, 1, model);
    },

    ADD_MARKET_MODEL(state, marketModel = {}) {
      if (!marketModel?.id) return;

      const existingIndex = state.marketModels
        .findIndex((existingMarketModel) => existingMarketModel.id === marketModel.id);

      existingIndex === -1
        ? state.marketModels.push(marketModel)
        : state.marketModels.splice(existingIndex, 1, marketModel);
    },

    UPDATE_MARKET_MODEL_DATA_OPTIONS(state, {
      marketModelId = '',
      marketModelOptions = {},
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId], 'options', marketModelOptions);
    },

    UPDATE_MARKET_MODEL_DATA_CATEGORIES(state, {
      marketModelId = '',
      categories = {},
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId], 'categories', categories);
    },

    UPDATE_MARKET_MODEL_DATA_GROUPS(state, {
      marketModelId = '',
      groups = {},
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId], 'groups', groups);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_REQUEST(state, {
      marketModelId = '',
      request = INITIAL_DYNAMIC_IMAGES_REQUEST_ITEM(),
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'request', request);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_OPTIMIZABLE(state, {
      marketModelId = '',
      optimizable = false,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'optimizable', optimizable);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_LOADING(state, {
      marketModelId = '',
      loading = false,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'loading', loading);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_SOURCES(state, {
      marketModelId = '',
      images = {},
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'sources', images);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_MODE(state, {
      marketModelId = '',
      activeMode = DYNAMIC_IMAGE_MODES.studio,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'activeMode', activeMode);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_MODE_CHANGED(state, {
      marketModelId = '',
      hasChanged = true,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      if (state.marketModelData[marketModelId].dynamicImages?.activeModeChanged === hasChanged) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'activeModeChanged', hasChanged);
    },

    UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_STUDIO_INDEX(state, {
      marketModelId = '',
      activeStudioIndex = 0,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].dynamicImages, 'activeStudioIndex', activeStudioIndex);
    },

    UPDATE_MARKET_MODEL_DELIVERY_DATA_REQUEST(state, {
      marketModelId = '',
      request = INITIAL_DELIVERY_DATA_REQUEST_ITEM(),
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].deliveryData, 'request', request);
    },

    UPDATE_MARKET_MODEL_DELIVERY_DATA_LOADING(state, {
      marketModelId = '',
      loading = false,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].deliveryData, 'loading', loading);
    },

    UPDATE_MARKET_MODEL_DELIVERY_DATA_ERROR(state, {
      marketModelId = '',
      error = false,
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId].deliveryData, 'error', error);
    },

    UPDATE_MARKET_MODEL_DELIVERY_DATA_INFO(state, {
      marketModelId = '',
      info = INITIAL_DELIVERY_DATA_INFO(),
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;

      const newInfo = INITIAL_DELIVERY_DATA_INFO();

      Object.keys(newInfo).forEach((key) => {
        if (info[key]) {
          newInfo[key] = info[key];
        }
      });

      this._vm.$set(state.marketModelData[marketModelId].deliveryData, 'info', newInfo);
    },

    UPDATE_MARKET_MODEL_DATA_OPTIONS_INVENTORY(state, {
      marketModelId = '',
      optionsInventory = {},
    } = {}) {
      if (!state?.marketModelData?.[marketModelId]) return;
      this._vm.$set(state.marketModelData[marketModelId], 'optionsInventory', optionsInventory);
    },

  },
  actions: {
    async initMarketModelStates({ commit, dispatch }, { marketModelData = {} } = {}) {
      commit('ADD_MARKET_MODEL', marketModelData);
      await dispatch('initMarketModelDataState', marketModelData.id);
      await dispatch('initMarketModelConfigurationState', { marketModelId: marketModelData.id });
      await dispatch('initFinancingState', marketModelData.id);
    },

    async initMarketModelDataState({ getters, commit }, marketModelId) {
      // @todo Could this do something else that's useful?
      if (getters.getMarketModelData(marketModelId)) return;
      commit('UPDATE_MARKET_MODEL_DATA_STATE', { marketModelId });
    },

    async fetchAndSetModels({ getters, commit, dispatch }, {
      fromYear = new Date().getFullYear() - 1,
      co = 'gte',
    } = {}) {
      try {
        const modelsResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetModelsUrl,
          params: {
            year: fromYear,
            co,
          },
        });

        const { data: models = [] } = modelsResponse;
        commit('UPDATE_MODELS', models);
      } catch (error) {
        commit('UPDATE_MODELS');
        dispatch('setAlert', {
          key: 'getModelsError',
          message: 'error.getModels',
          log: error.response,
        });
        consoleLog(error);
        throw error;
      }

      if (!getters.getModels.length) {
        throw new Error('error.noModelsFound');
      }
    },

    async fetchAndSetModelByUrlKey({ getters, commit, dispatch }, {
      urlKey = '',
    } = {}) {
      try {
        const modelResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetModelByUrlKeyUrl,
          params: {
            urlKey,
          },
        });

        const { data: modelArray = [] } = modelResponse;
        const [model] = modelArray;
        commit('ADD_MODEL', model);
      } catch (error) {
        dispatch('setAlert', {
          key: 'getModelError',
          message: 'error.getModel',
          log: error.response,
        });
        consoleLog(error);
        throw error;
      }

      if (!getters.getModelByUrlKey(urlKey)) {
        throw new Error('error.modelNotFound');
      }
    },

    async fetchAndSetMarketModels({ getters, dispatch }, {
      modelId = getters.getActiveModelId,
      skipSelectionBasedData = true,
      skipOptions = false,
    } = {}) {
      const { code: modelCode, year: modelYear } = getters.getModelById(modelId) ?? {};

      let marketModelsResponse;

      try {
        marketModelsResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetMarketModelsUrl,
          params: {
            modelCode,
            modelYear,
          },
        });
      } catch (error) {
        dispatch('setAlert', { message: 'error.fetchMarketModels' });
        consoleLog(error);
        throw error;
      }

      const { data: marketModels = [] } = marketModelsResponse ?? {};

      if (!marketModels?.length) {
        throw new Error('error.noMarketModelsFound');
      }

      const marketModelPromises = marketModels.map(async (marketModel) => {
        await dispatch('initMarketModelStates', { marketModelData: marketModel });

        if (!skipOptions) {
          try {
            await dispatch('fetchAndSetMarketModelOptions', {
              marketModelId: marketModel.id,
              preselectStandard: !getters.isChangingOrder(modelId),
              skipSelectionBasedData,
            });
          } catch (error) {
            await dispatch('resetModelConfiguratorStepsAfterCurrent', { modelId });
            throw error;
          }
        }

        try {
          await dispatch('initFinancingSettings', {
            financingStateKey: marketModel.id,
            financingConfig: getters.getMarketModelFinancingConfig(marketModel.id),
            force: true,
          });
        } catch (error) {
          await dispatch('resetModelConfiguratorStepsAfterCurrent', { modelId });
          throw error;
        }

        await dispatch('initWltpState', {
          marketModelId: marketModel.id,
          skipWltpData: true,
        });
      });

      await Promise.all(marketModelPromises);
    },

    async fetchAndSetMarketModelOptions({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      preselectStandard = true,
      skipSelectionBasedData = true,
    } = {}) {
      if (!getters.getMarketModelById(marketModelId)) {
        dispatch('setAlert', { key: 'errorMmNotFound', message: 'error.marketModelNotFound' });
        throw new Error('Market model does not exist');
      }

      try {
        const { data: marketModelOptions } = await httpInternal({
          method: 'get',
          url: getters.apiGetMarketModelOptionsUrl,
          params: {
            marketModelId,
          },
        });

        const {
          categories = {},
          groups = {},
          items = {},
        } = marketModelOptions;

        commit('UPDATE_MARKET_MODEL_DATA_CATEGORIES', { marketModelId, categories });
        commit('UPDATE_MARKET_MODEL_DATA_GROUPS', { marketModelId, groups });
        commit('UPDATE_MARKET_MODEL_DATA_OPTIONS', { marketModelId, marketModelOptions: items });
      } catch (error) {
        commit('UPDATE_MARKET_MODEL_DATA_CATEGORIES');
        commit('UPDATE_MARKET_MODEL_DATA_GROUPS');
        commit('UPDATE_MARKET_MODEL_DATA_OPTIONS');
        dispatch('setAlert', { key: 'errorGetMmOptions', message: 'error.getMarketModelOptions', log: error });
        throw error;
      }

      const modelId = getters.getMarketModelModelId(marketModelId);

      if (getters.getModelUsesInventory(modelId)) {
        try {
          await dispatch('fetchAndSetMarketModelOptionsInventory', { marketModelId });
        } catch (error) {
          consoleLog(error);
          throw error;
        }
      }

      if (preselectStandard) await dispatch('preselectMarketModelStandardOptions', { marketModelId });

      // @todo This is backwards and the reason everything gets loaded in the line step...
      // @todo Needs to fix correct initial loading of selectionBasedData in Configuration step before it can be fixed
      if (!skipSelectionBasedData) {
        try {
          await dispatch('fetchAndSetSelectionBasedData', { marketModelId, isStandardSelection: preselectStandard });
        } catch (error) {
          // Do nothing..
        }
      }

      await dispatch('initMileageYearPriceStateSettings', marketModelId);

      getters.getModelDynamicStepsData({ modelId }).forEach((step) => {
        dispatch('checkStepOptionsRelations', { marketModelId, stepKey: step.key });
      });
    },

    async fetchAndSetMarketModelOptionsInventory({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) {
      if (!marketModelId) return;

      let inventoryResponse;
      try {
        inventoryResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetInventoryUrl,
          params: {
            marketModelId,
          },
        });
      } catch (error) {
        consoleLog(error);
        dispatch('setAlert', { key: 'getInventoryError', message: 'error.getInventory' });
        throw error;
      }
      const { data: inventory = {} } = inventoryResponse;

      const modelId = getters.getMarketModelModelId(marketModelId);

      if (getters.isChangingOrder(modelId)) {
        const orderSelections = getters.getOpenedOrderState(modelId)?.selections ?? [];
        const selectionIds = orderSelections.map((selection) => selection.id);

        selectionIds.forEach((selectionId) => {
          inventory[selectionId] = union(
            inventory[selectionId],
            selectionIds.filter((id) => id !== selectionId),
          );
        });
      }

      commit('UPDATE_MARKET_MODEL_DATA_OPTIONS_INVENTORY', { marketModelId, optionsInventory: inventory });
    },

    async setMarketModelDynamicImagesActiveMode({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      activeMode = getters.getMarketModelDynamicImagesAvailableModes({ marketModelId })?.[0],
      changedByUser = true,
    } = {}) {
      if (activeMode === getters.getMarketModelDynamicImagesActiveMode(marketModelId)) return;

      const modeToSelect = getters.getMarketModelDynamicImagesAvailableModes(marketModelId).includes(activeMode)
        ? activeMode
        : getters.getMarketModelDynamicImagesAvailableModes(marketModelId)?.[0];

      commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_MODE', { marketModelId, activeMode: modeToSelect });
      if (changedByUser) commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_MODE_CHANGED', { marketModelId, hasChanged: true });
    },

    async setMarketModelDynamicImagesActiveStudioIndex({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      activeStudioIndex = 0,
    } = {}) {
      const studioImages = getters.getMarketModelStudioImages({
        marketModelId,
        type: DYNAMIC_IMAGE_TYPES.all,
        useDynamic: true,
      });

      const lastIndex = (studioImages?.length ?? 0) - 1;
      const studioIndex = roundNumber(
        activeStudioIndex > lastIndex ? 0 : activeStudioIndex,
      );

      commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_ACTIVE_STUDIO_INDEX', { marketModelId, activeStudioIndex: studioIndex });
    },

    async fetchAndSetMarketModelDynamicImages({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      isStandardSelection = false,
    } = {}) {
      const setLoadingState = (loading = false) => commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_LOADING', { marketModelId, loading });

      try {
        const {
          request,
          loading,
        } = getters.getMarketModelDynamicImagesData(marketModelId) ?? {};

        const selections = getters.getMarketModelSelectionIds({
          marketModelId,
          skipBundled: false,
          sortSelections: true,
        })
          ?.filter?.((selectionId) => !!getters.getMarketModelOptionById({
            marketModelId,
            marketModelOptionId: selectionId,
          })?.triggerDynamicImage)
          ?.map?.((selectionId) => ({ id: selectionId }));

        const selectionsKey = JSON.stringify(selections);

        if (request?.id === selectionsKey) return;

        request?.abortController?.abort
          ? request.abortController.abort()
          : setLoadingState(false);

        const abortController = new AbortController();

        commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_REQUEST', {
          marketModelId,
          request: {
            id: selectionsKey,
            abortController,
          },
        });

        if (!loading) setLoadingState(true);

        const configImagesResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetMarketModelDynamicImagesUrl,
          signal: abortController.signal,
          params: {
            marketModelId,
            selections: selectionsKey,
            shouldCacheDynamicImages: isStandardSelection,
          },
        });

        const { data } = configImagesResponse;

        const images = {
          all: data?.all ?? [],
          exterior: data?.exterior ?? [],
          exterior360: data?.exterior360 ?? [],
          interior: data?.interior ?? [],
          interior360: data?.interior360 ?? [],
        };

        const optimizable = data?.optimizable ?? false;

        commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_SOURCES', { marketModelId, images });
        commit('UPDATE_MARKET_MODEL_DATA_DYNAMIC_IMAGES_OPTIMIZABLE', { marketModelId, optimizable });

        if (!getters.getMarketModelDynamicImagesActiveModeChanged(marketModelId)) {
          await dispatch('setMarketModelDynamicImagesActiveMode', { marketModelId, changedByUser: false });
        }

        setTimeout(() => {
          setLoadingState(false);
        }, 0);
      } catch (error) {
        if (axios.isCancel(error)) return;

        // @todo This should set an error state, default images shown and notification that images are not loaded

        setLoadingState(false);
        consoleLog(error);
      }
    },

    async fetchAndSetMarketModelDeliveryData({ getters, commit }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      isStandardSelection = false,
    } = {}) {
      if (!marketModelId) return;

      const setLoadingState = (loading = false) => commit('UPDATE_MARKET_MODEL_DELIVERY_DATA_LOADING', { marketModelId, loading });
      const setErrorState = (error = false) => commit('UPDATE_MARKET_MODEL_DELIVERY_DATA_ERROR', { marketModelId, error });

      try {
        const { request, loading } = getters.getMarketModelDeliveryData(marketModelId) ?? {};

        const selectionIds = JSON.stringify(
          getters.getMarketModelSelectionIds({
            marketModelId,
            skipBundled: false,
            sortSelections: true,
          }),
        );

        if (request?.id === selectionIds) return;

        request?.abortController?.abort
          ? request.abortController.abort()
          : setLoadingState(false);

        const abortController = new AbortController();

        commit('UPDATE_MARKET_MODEL_DELIVERY_DATA_REQUEST', {
          marketModelId,
          request: {
            id: selectionIds,
            abortController,
          },
        });

        if (!loading) setLoadingState(true);

        const deliveryDataResponse = await httpInternal({
          method: 'get',
          url: getters.apiGetMarketModelDeliveryDataUrl,
          signal: abortController.signal,
          params: {
            marketModelId,
            selectionIds,
            shouldCacheData: isStandardSelection,
          },
        });
        const { data: info = {} } = deliveryDataResponse ?? {};

        commit('UPDATE_MARKET_MODEL_DELIVERY_DATA_INFO', { marketModelId, info });

        setTimeout(() => {
          setLoadingState(false);
        }, 0);
      } catch (error) {
        if (axios.isCancel(error)) return;

        consoleLog(error);
        setLoadingState(false);
        setErrorState(true);
        throw error;
      }
    },

    async fetchAndSetSelectionBasedData({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      isStandardSelection = false,
    } = {}) {
      if (getters.isStockCarConfigurator()) return;

      const selectionBasedPromises = [
        dispatch('fetchAndSetMarketModelDynamicImages', { marketModelId, isStandardSelection }),
        dispatch('fetchAndSetMarketModelDeliveryData', { marketModelId, isStandardSelection }),
        dispatch('fetchAndSetWltpData', { marketModelId }),
      ];

      await Promise.all(selectionBasedPromises);
    },

  },
};

export default VehicleDataStore;
