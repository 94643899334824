import {
  calculateWeightBasedTax,
  createLeasingItemData,
} from '@/assets/js/helpers/financing-helpers';
import { FINANCING_OPTIONS, LEASING_KEYS, LOAN_KEYS } from '@/constants/constants-financing';

const NewCarTaxationStore = {
  getters: {
    showNewCarTaxationDebugData: () => process.env.environment !== 'prod',

    useMarketModelWeightBasedTax: (state, getters) => !!getters.appConfig?.newCarTaxation?.weightBasedTax?.active,

    getConfigurationWeightBasedTax: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      if (!getters.useMarketModelWeightBasedTax) return 0;

      const marketModel = getters.getMarketModelById(marketModelId);
      const model = getters.getModelById(marketModel?.modelId);

      if (model?.commercialVehicle) return 0;

      const weight = marketModel?.carData?.unladenWeight;
      if (!weight) return 0;

      const { limit = 0, costPerKilo = 0 } = getters.appConfig?.newCarTaxation?.weightBasedTax ?? {};

      // @todo Add FOSSIL CAR check and component of calculation

      return calculateWeightBasedTax({ limit, costPerKilo, weight });
    },

    getConfigurationWeightBasedTaxPerFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      useDefaultFinancingSettings = false,
    } = {}) => {
      const weightBasedTax = getters.getConfigurationWeightBasedTax();

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const weightBasedTaxLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice: weightBasedTax,
          financingConfig,
          includeFees: false,
          useDefaultFinancingSettings,
        });

        return weightBasedTaxLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        const weightBasedTaxLeasingData = getters.getConfigurationWeightBasedTaxLeasingDataParams({
          marketModelId,
          useDefaultFinancingSettings,
        });
        const weightBasedTaxLeasingValues = getters.getLeasingValues(weightBasedTaxLeasingData);

        return weightBasedTaxLeasingValues[LEASING_KEYS.monthlyInstallment];
      }

      return weightBasedTax;
    },

    getConfigurationWeightBasedTaxLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
    } = {}) => {
      const weightBasedTaxLeasingDataParams = getters.getSharedLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      const weightBasedTax = getters.getConfigurationWeightBasedTax({ marketModelId });

      if (weightBasedTax === 0) return weightBasedTaxLeasingDataParams;

      const weightBasedTaxFinancingConfig = getters.getMarketModelFinancingConfig(marketModelId);
      const weightBasedTaxLeasingSettings = getters.getLeasingSettings({
        financingStateKey: marketModelId,
        financingConfig: weightBasedTaxFinancingConfig,
        useDefaultFinancingSettings,
      });

      const {
        [LEASING_KEYS.residualValuePercent]: weightBasedTaxResidualValuePercent,
      } = weightBasedTaxLeasingSettings;

      weightBasedTaxLeasingDataParams.items.push(createLeasingItemData({
        cashPrice: weightBasedTax,
        residualValuePercent: weightBasedTaxResidualValuePercent,
        vatRatePercent: 0,
        taxes: weightBasedTax,
        includeFees: false,
        isAnnualService: false,
      }));

      return weightBasedTaxLeasingDataParams;
    },

    useMarketModelElectricVat: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
    } = {}) => {
      const electricVatActive = getters.appConfig?.newCarTaxation?.electricVat?.active;

      if (!electricVatActive) return false;

      const marketModel = getters.getMarketModelById(marketModelId);
      const model = getters.getModelById(marketModel?.modelId) ?? {};
      const { commercialVehicle = false } = model;

      if (commercialVehicle) return false;

      const isElectricVehicle = getters.isMarketModelElectric({ marketModelId });
      const notCompanyFinancing = !getters.isFinancingCompany(marketModel?.modelId);

      return isElectricVehicle && notCompanyFinancing;
    },

    getConfigurationElectricVatBasisSelectionIds: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return [];

      return getters.getMarketModelOptions({ marketModelId })
        .filter((selection) => includedSelectionIds
          ?.includes(selection.id) && selection?.financing?.vatRatePercent === 0)
        .map((selection) => selection.id);
    },

    getConfigurationElectricVatBasis: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
      discountFinancingOptionType = getters.getSelectedFinancingOption(),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return 0;

      const marketModelPrice = getters.getMarketModelAndDeliveryPrice({
        marketModelId,
        financingOptionType: FINANCING_OPTIONS.cash,
        ignoreDiscount,
        discountFinancingOptionType,
      });

      const { registrationTax = 0 } = getters.getMarketModelById(marketModelId)?.financing ?? {};

      const marketModelBasis = marketModelPrice - registrationTax;

      const electricVatBasisSelectionIds = getters.getConfigurationElectricVatBasisSelectionIds({
        marketModelId,
        includedSelectionIds,
      });

      const selectionsBasis = getters.getMarketModelSelectionsTotalCost({
        marketModelId,
        selectionIds: electricVatBasisSelectionIds,
        financingOptionType: FINANCING_OPTIONS.cash,
        ignoreDiscount,
        discountFinancingOptionType,
      });

      return marketModelBasis + selectionsBasis;
    },

    getConfigurationElectricVatFreeAmount: (state, getters) => {
      const { freeAmount = 0 } = getters.appConfig?.newCarTaxation?.electricVat ?? {};
      return freeAmount;
    },

    getConfigurationElectricVatRatePercent: (state, getters) => {
      const { vatRatePercent = 0 } = getters.appConfig?.newCarTaxation?.electricVat ?? {};
      return vatRatePercent;
    },

    getConfigurationElectricVatInfo: (state, getters) => {
      const { infoTitle = '' } = getters.appConfig?.newCarTaxation?.electricVat ?? {};
      const { infoText = '' } = getters.appConfig?.newCarTaxation?.electricVat ?? {};

      return { infoTitle, infoText };
    },

    getConfigurationWeightBasedTaxInfo: (state, getters) => {
      const { infoTitle = '' } = getters.appConfig?.newCarTaxation?.weightBasedTax ?? {};
      const { infoText = '' } = getters.appConfig?.newCarTaxation?.weightBasedTax ?? {};

      return { infoTitle, infoText };
    },

    getConfigurationElectricVatBasisAfterFreeAmount: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
      discountFinancingOptionType = getters.getSelectedFinancingOption(),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return 0;

      const freeAmount = getters.getConfigurationElectricVatFreeAmount;

      const basisAfterFreeAmount = getters.getConfigurationElectricVatBasis({
        marketModelId,
        includedSelectionIds,
        ignoreDiscount,
        discountFinancingOptionType,
      }) - freeAmount;

      return basisAfterFreeAmount >= 0
        ? basisAfterFreeAmount
        : 0;
    },

    getConfigurationElectricVatShareWithVat: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      discountFinancingOptionType = getters.getSelectedFinancingOption(),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return 0;

      const basis = getters.getConfigurationElectricVatBasis({
        marketModelId,
        includedSelectionIds,
        discountFinancingOptionType,
      });
      const basisAfterFreeAmount = getters.getConfigurationElectricVatBasisAfterFreeAmount({
        marketModelId,
        includedSelectionIds,
        discountFinancingOptionType,
      });

      return basisAfterFreeAmount / basis;
    },

    getConfigurationElectricVatProportionateVatRate: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      discountFinancingOptionType = getters.getSelectedFinancingOption(),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return 0;

      const vatRatePercent = getters.getConfigurationElectricVatRatePercent ?? 0;
      const shareWithVat = getters.getConfigurationElectricVatShareWithVat({
        marketModelId,
        includedSelectionIds,
        discountFinancingOptionType,
      });

      return shareWithVat * (vatRatePercent / 100);
    },

    getConfigurationElectricVatTotal: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
      ignoreDiscount = false,
      discountFinancingOptionType = getters.getSelectedFinancingOption(),
    } = {}) => {
      if (!getters.useMarketModelElectricVat({ marketModelId })) return 0;

      const { vatRatePercent = 0 } = getters.appConfig?.newCarTaxation?.electricVat ?? {};

      const basisAfterFreeAmount = getters.getConfigurationElectricVatBasisAfterFreeAmount({
        marketModelId,
        includedSelectionIds,
        ignoreDiscount,
        discountFinancingOptionType,
      });

      return basisAfterFreeAmount * (vatRatePercent / 100);
    },

    getConfigurationElectricVatTotalPerFinancingOption: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      financingOptionType = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      }),
      useDefaultFinancingSettings = false,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
    } = {}) => {
      const electricVatTotal = getters.getConfigurationElectricVatTotal({
        marketModelId,
        includedSelectionIds,
        discountFinancingOptionType: financingOptionType,
      });

      if (financingOptionType === FINANCING_OPTIONS.loan) {
        const financingConfig = getters.getMarketModelFinancingConfig(marketModelId) || {};

        const electricVatLoanValues = getters.getLoanValues({
          financingStateKey: marketModelId,
          cashPrice: electricVatTotal,
          financingConfig,
          includeFees: false,
          useDefaultFinancingSettings,
        });
        return electricVatLoanValues[LOAN_KEYS.monthlyInstallment];
      }

      if (financingOptionType === FINANCING_OPTIONS.leasing) {
        // const electricVatBasisSelectionIds = getters.getConfigurationElectricVatBasisSelectionIds({
        //   marketModelId,
        //   includedSelectionIds,
        // });

        const electricVatConfigurationLeasingValues = getters.getConfigurationLeasingValues({
          marketModelId,
          useDefaultFinancingSettings,
          includedSelectionIds,
          // includedSelectionIds: electricVatBasisSelectionIds,
          addElectricProportionateVat: true,
          roundNumbers: true,
        });

        return electricVatConfigurationLeasingValues[LEASING_KEYS.electricVatPerMonth];
      }

      return electricVatTotal;
    },

    getConfigurationElectricVatLeasingDataParams: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      useDefaultFinancingSettings = false,
      includedSelectionIds = getters.getMarketModelSelectionIds({ marketModelId }),
    } = {}) => {
      const electricVatLeasingDataParams = getters.getSharedLeasingDataParams({
        marketModelId,
        useDefaultFinancingSettings,
      });

      const electricVat = getters.getConfigurationElectricVatTotal({
        marketModelId,
        includedSelectionIds,
        discountFinancingOptionType: FINANCING_OPTIONS.leasing,
      });

      if (electricVat === 0) return electricVatLeasingDataParams;

      electricVatLeasingDataParams.items.push(createLeasingItemData({
        cashPrice: electricVat,
        residualValuePercent: 0,
        vatRatePercent: 0,
        taxes: 0,
        includeFees: false,
        isAnnualService: false,
        isElectricVat: true,
      }));

      return electricVatLeasingDataParams;
    },

  },
};

export default NewCarTaxationStore;
